import styled from 'styled-components'

const Divider = styled.hr`
  clear: both;
  border: 1px solid ${({ theme }) => theme.colors.highlight};
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  margin: 32px 0;
`

export default Divider
