import { PortableText } from '@portabletext/react'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React from 'react'
import styled from 'styled-components'
import BlogSidebar from '../components/BlogSidebar'
import FlairText from '../components/FlairText'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import Seo from '../components/Seo'
import SerifText from '../components/SerifText'
import StripImageDimensionAttributes from '../components/StripImageDimensionAttributes'
import { sanityConfig } from '../constants'
import { PortableTextBlock } from '../types/PortableTextBlock'
import { getLinkConfig } from '../utils'

interface Props {
  data: {
    sanityPost: {
      title: string
      publishedAt: string
      _rawBody: any
      mainImage: {
        asset: {
          altText: string
          gatsbyImageData: IGatsbyImageData
        }
      }
    }
    file: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

function SanityBlogPost({ data }: Props) {
  const {
    sanityPost: { title, publishedAt, _rawBody, mainImage },
    file,
  } = data
  const imageSrc =
    mainImage?.asset?.gatsbyImageData || file?.childImageSharp?.gatsbyImageData
  const imageAlt = mainImage?.asset
    ? mainImage.asset.altText
    : 'Hand-crafted acrylic placecards'
  return (
    <Layout>
      <Seo title={title} />
      <FeaturedImage image={imageSrc} alt={imageAlt || ''} loading="eager" />
      <FlexPagePadding>
        <Article>
          <TitleBlock>
            <Title as="h1" dangerouslySetInnerHTML={{ __html: title }} />
            <Date>{format(publishedAt, 'MMMM D, YYYY')}</Date>
          </TitleBlock>
          <StripImageDimensionAttributes>
            <Content>
              <PortableText
                value={_rawBody}
                components={portableTextComponents}
              />
            </Content>
          </StripImageDimensionAttributes>
        </Article>
        <BlogSidebarWithLayout />
      </FlexPagePadding>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostQuery($postId: String) {
    sanityPost(id: { eq: $postId }) {
      title
      publishedAt
      _rawBody
      body {
        children {
          text
        }
      }
      mainImage {
        asset {
          altText
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
    file(relativePath: { eq: "placecards-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`

const portableTextComponents = {
  marks: {
    link: ({ children, value }: { children: any; value?: any }) => {
      const { href } = value
      const linkConfig = getLinkConfig(href)
      return (
        <Anchor
          as={linkConfig.component}
          to={linkConfig.isInternal ? linkConfig.url : undefined}
          href={linkConfig.isExternal ? linkConfig.url : undefined}
          target={linkConfig.isExternal ? '_blank' : '_self'}
          rel={linkConfig.isExternal ? 'noreferrer' : null}
        >
          {children}
        </Anchor>
      )
    },
  },
  types: {
    postCtaButton: (block: PortableTextBlock) => {
      return <PostCtaButton value={block.value} />
    },
    postImage: (block: PortableTextBlock) => {
      return <PostImage value={block.value} />
    },
    postGallery: (block: PortableTextBlock) => {
      return <PostGallery value={block.value} />
    },
    separator: () => {
      return <hr />
    },
  },
}

const PostCtaButton = ({
  value,
}: {
  value: {
    link: {
      displayText: string
      url: string
    }
    openInNewTab?: boolean
    alignment?: 'alignleft' | 'aligncenter' | 'alignright'
  }
}) => {
  const {
    link: { displayText, url },
    openInNewTab,
    alignment,
  } = value
  const linkConfig = getLinkConfig(url)
  return (
    <div className={`wp-block-button ${alignment || 'aligncenter'}`}>
      {/* eslint-disable-next-line */}

      <Anchor
        as={linkConfig.component}
        to={linkConfig.isInternal ? linkConfig.url : undefined}
        href={linkConfig.isExternal ? linkConfig.url : undefined}
        className="wp-block-button__link"
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noreferrer' : null}
      >
        {displayText}
      </Anchor>
    </div>
  )
}

const PostGallery = ({
  value,
}: {
  value: {
    images: {
      _key: string
      asset: any
      alt?: string
    }[]
  }
}) => {
  const { images } = value
  return (
    <figure className="wp-block-gallery">
      <ul className="blocks-gallery-grid">
        {images.map(image => {
          const { _key, asset, alt } = image
          return (
            <li key={_key} className="blocks-gallery-item">
              <figure>
                <GatsbyImage
                  image={getGatsbyImageData(
                    asset,
                    { width: 750 },
                    sanityConfig
                  )}
                  alt={alt}
                />
              </figure>
            </li>
          )
        })}
      </ul>
    </figure>
  )
}

const PostImage = ({
  value,
}: {
  value: {
    alignment?:
      | 'alignleft'
      | 'aligncenter'
      | 'alignright'
      | 'alignwide'
      | 'alignfull'
    alt: string
    asset: any
    caption?: string
    width?: number
  }
}) => {
  const { alignment, alt, asset, caption, width } = value
  const alignedHorizontal =
    alignment === 'alignleft' ||
    alignment === 'aligncenter' ||
    alignment === 'alignright'
  const content = (
    <>
      <GatsbyImage
        image={getGatsbyImageData(asset, { width: width ?? 750 }, sanityConfig)}
        alt={alt}
      />
      {caption && <figcaption>{caption}</figcaption>}
    </>
  )
  return alignedHorizontal ? (
    <div className="wp-block-image">
      <figure className={alignment}>{content}</figure>
    </div>
  ) : (
    <figure className={`wp-block-image ${alignment}`}>{content}</figure>
  )
}

const FeaturedImage = styled(GatsbyImage)<{ image: any }>`
  height: 250px;
  @media (min-width: 1200px) {
    height: 60vh;
  }
`

const FlexPagePadding = styled(PagePadding)`
  @media (min-width: 1200px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const Article = styled.article`
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 32px;
  font-size: 0.875rem;
  @media (min-width: 1200px) {
    margin-right: 64px;
    padding-top: 64px;
    padding-bottom: 48px;
  }
`

const BlogSidebarWithLayout = styled(BlogSidebar)`
  max-width: 750px;
  margin: 32px auto 0;
  @media (min-width: 1200px) {
    max-width: 285px;
    position: sticky;
    top: 16px;
    right: 0;
  }
`

const TitleBlock = styled.header`
  margin: 32px 0;
  @media (min-width: 1200px) {
    margin: 0 0 40px;
  }
`

const Title = styled(SerifText)`
  font-size: 2rem;
  color: ${props => props.theme.colors.highlight};
  margin-bottom: 16px;

  @media (min-width: 751px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1058px) {
    font-size: 3.375rem;
  }
`

const Date = styled(FlairText)`
  font-size: 0.75rem;
  margin: 0 0 16px;
`

const Anchor = styled.a``

const Content = styled.div`
  font-size: 0.875rem;
  line-height: 1.8285714286em;

  /* Clearfix in case of floating images */
  &::after {
    visibility: hidden;
    display: block;
    content: '';
    clear: both;
    height: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
    margin: 16px 0;
    @media (min-width: 751px) {
      margin: 32px 0;
    }
  }

  h1,
  h2 {
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1.2em;
  }

  h1,
  h2 {
    font-size: 2rem;
    @media (min-width: 751px) {
      font-size: 2.5rem;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.fonts.tertiary};
    font-weight: 300;
    letter-spacing: 0.3em;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 300;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }

  blockquote {
    position: relative;
    margin: 2rem 0;
    padding-right: 34px;
    @media (min-width: 751px) {
      padding-left: 4em;
    }
  }

  blockquote,
  blockquote p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1.2em;
    text-align: right;
    @media (min-width: 751px) {
      font-size: 1.5rem;
    }
  }

  blockquote::before {
    content: '';
    display: block;
    background: ${({ theme }) => theme.colors.highlight};
    width: 5px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  em {
    font-style: italic;
  }

  hr {
    clear: both;
    border: 1px solid ${({ theme }) => theme.colors.highlight};
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin: 32px 0;
  }

  ul {
    margin: 32px 0;
    line-height: 1.5;
    list-style: disc;
    padding-left: 2rem;
  }

  li {
    margin: 8px 0;
    padding-left: 16px;
  }

  img,
  iframe,
  video {
    max-width: 100%;
  }

  .aligncenter {
    text-align: center;
  }

  .wp-block-button {
    margin: 32px 0;
    &__link {
      /* Button.tsx */
      background: none;
      border: none;
      display: inline-block;
      color: ${props => props.theme.colors.neutral};
      font-size: 0.75rem;
      font-family: ${props => props.theme.fonts.tertiary};
      letter-spacing: 0.3em;
      line-height: 1.375em;
      text-align: center;
      text-transform: uppercase;
      padding: 1.35em 4em;
      position: relative;
      z-index: 1;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: ${props => props.theme.colors.secondary};
        width: 100%;
        height: 100%;
        transition: left 0.3s, top 0.3s;
        z-index: -1;
      }

      &:hover::before {
        left: 0.5em;
        top: 0.5em;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: ${props => props.theme.colors.softContrast};
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      /* Overrides */
      text-decoration: none;
      color: ${props => props.theme.colors.offWhite};
      &::after {
        background: ${props => props.theme.colors.highlight};
      }
    }
  }

  .wp-block-gallery {
    margin: 2rem 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0;
      padding: 0;

      figure {
        margin: 0.5rem;
      }
    }

    .blocks-gallery-grid {
      display: flex;
      flex-flow: row wrap;
    }

    .blocks-gallery-item {
      flex: 1 0 200px;
      width: 30%;
    }
  }

  .wp-block-image {
    margin: 32px 0;

    figure {
      @media (min-width: 575px) {
        margin: 32px;

        &.alignleft,
        &.alignright {
          margin: 0 0 32px;
          max-width: 40%;
        }

        &.alignleft {
          float: left;
          margin-right: 32px;
        }

        &.alignright {
          float: right;
          margin-left: 32px;
        }
      }

      @media (min-width: 768px) {
        margin: 32px 48px;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }

    figcaption {
      font-size: 0.875rem;
      line-height: 1.5;
      margin-top: 1rem;
      overflow-wrap: break-word;
      text-align: center;
    }

    &.alignwide {
      margin-left: 0;
      margin-right: 0;

      img {
        width: 100%;
      }
    }

    &.alignfull {
      margin-left: calc((50vw - 50%) * -1);
      margin-right: calc((50vw - 50%) * -1);

      img {
        width: 100vw;
        max-height: 60vh;
        object-fit: cover;
      }
    }
  }
`

export default SanityBlogPost
