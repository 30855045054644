import React, { useEffect, useRef } from 'react'

function StripImageDimensionAttributes({ children }) {
  const parentRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (parentRef) {
      let dangerousImages = parentRef.current
        ? parentRef.current.querySelectorAll('img')
        : []
      dangerousImages.forEach(element => {
        element.removeAttribute('height')
        element.removeAttribute('width')
      })
    }
  })
  return <div ref={parentRef}>{children}</div>
}

export default StripImageDimensionAttributes
