import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Divider from '../components/Divider'
import { getLinkConfig } from '../utils'
import Button from './Button'

function BlogSidebar({ className }: Props) {
  const { sanityComponent } = useStaticQuery<BlogSidebarModel>(query)
  const { images, links, messages } = sanityComponent
  const [imageData] = images
  return (
    <Aside className={className}>
      <DividerWithLayout />
      <Flex>
        <Image
          image={imageData.image.asset.gatsbyImageData}
          alt={imageData.image.alt || ''}
        />
        <TextContent>
          {messages.map(message => {
            return <Text key={message._key}>{message.value}</Text>
          })}
          <List>
            {links.map(link => {
              const { _key, displayText, url } = link
              const linkConfig = getLinkConfig(url)
              return (
                <Item key={_key}>
                  <ButtonLink
                    as={linkConfig.component}
                    to={linkConfig.isInternal ? linkConfig.url : undefined}
                    href={linkConfig.isExternal ? linkConfig.url : undefined}
                  >
                    {displayText}
                  </ButtonLink>
                </Item>
              )
            })}
          </List>
        </TextContent>
      </Flex>
    </Aside>
  )
}

export default BlogSidebar

const Aside = styled.aside`
  font-size: 0.875rem;
  line-height: 1.8285714286em;
`

const DividerWithLayout = styled(Divider)`
  @media (min-width: 1000px) {
    display: none;
  }
`

const Flex = styled.div`
  @media (min-width: 575px) {
    display: flex;
    flex-flow: row nowrap;
  }

  @media (min-width: 1000px) {
    flex-direction: column;
  }
`

const Image = styled(GatsbyImage)<{ image: any }>`
  display: block;
  width: 285px;
  height: 285px;
  margin: 0 auto;

  @media (min-width: 575px) and (max-width: 999px) {
    margin-right: 32px;
  }
`

const TextContent = styled.div`
  flex: 1;
`

const Text = styled.p`
  text-align: center;
  @media (min-width: 575px) {
    text-align: left;
  }
  @media (min-width: 575px) and (max-width: 999px) {
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const ButtonLink = styled(Button)`
  width: 100%;
`

const List = styled.ul`
  margin: 24px 0;
  display: flex;
  flex-flow: column nowrap;
`

const Item = styled.li`
  margin: 12px 0;
`

interface Props {
  className?: string
}

interface BlogSidebarModel {
  sanityComponent: {
    messages: {
      value: string
      _key: string
    }[]
    images: {
      _key: string
      uuid: string
      image: {
        alt: string
        asset: {
          gatsbyImageData: any
        }
      }
    }[]
    links: {
      url: string
      displayText: string
      _key: string
    }[]
  }
}

export const query = graphql`
  {
    sanityComponent(
      uuid: { eq: "blogPostSidebar" }
      images: { elemMatch: { uuid: { eq: "bioImage" } } }
    ) {
      messages {
        value
        _key
      }
      images {
        _key
        uuid
        image {
          alt
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 285)
          }
        }
      }
      links {
        url
        displayText
        _key
      }
    }
  }
`
